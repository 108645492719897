import clsx from 'clsx';
import { ComponentProps, useEffect, useState } from 'react';
import { ClassNames, DayPicker } from 'react-day-picker';
import styles from 'react-day-picker/dist/style.module.css';
import { useTranslation } from 'react-i18next';
import { LOCALES } from '../../utilities/dateUtilities';

export type CalendarVariant = 'default' | 'border-light' | 'border-medium';

type CalendarDatePickerProps = ComponentProps<typeof DayPicker> & {
  variant?: CalendarVariant;
};

/*
  A wrapper around React Day Picker that can be used for showing
  a persisting calendar day picker in the UI.
*/
const classNames: Partial<ClassNames> = {
  ...styles,
  day: `${styles['day']} text-grey-900 hover:!bg-brand-primary-50 border-transparent rounded-full text-button-m text-content`,
  // FIXME: These important styles are for appeasing storybook and are not needed for
  // production code.
  selected: `${styles['selected']} !bg-brand-primary-500 border-transparent text-white text-button-s hover:!text-grey-900`,
  today: `${styles['day_today']} !border-grey-900 rounded-circle`,
  day_button: `${styles['day_button']} !border-inherit border-[1px] bg-inherit`,
  chevron: `${styles['chevron']} !fill-content h-s`,
};

export const CalendarDatePicker = ({
  defaultMonth,
  onMonthChange,
  variant = 'default',
  ...restProps
}: CalendarDatePickerProps) => {
  const { i18n } = useTranslation();
  const [month, setSelectedMonth] = useState<Date>(defaultMonth ?? new Date());
  const computedClassName = clsx('m-0 bg-white rounded-xl p-4', {
    border: /border/.test(variant),
    'border-grey-200': variant === 'border-light',
    'border-grey-500': variant === 'border-medium',
  });
  const locale = LOCALES[i18n.language];

  useEffect(() => {
    defaultMonth && setSelectedMonth(defaultMonth);
  }, [defaultMonth]);

  return (
    <DayPicker
      {...restProps}
      classNames={classNames}
      className={computedClassName}
      month={month}
      onMonthChange={(date) => {
        setSelectedMonth(date);
        onMonthChange?.(date);
      }}
      locale={locale}
    />
  );
};
